import React, {useEffect} from "react";
import { AgFinancialCharts } from "ag-charts-react";
import { AgCharts as AgChartsEnterprise } from "ag-charts-enterprise";
import fetchData from "../utils/Fetch";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useSearchParams} from "react-router-dom";

AgChartsEnterprise.setLicenseKey("Using_this_{AG_Charts}_Enterprise_key_{AG-069772}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{cointool}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{cointool}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{cointool}_need_to_be_licensed___{cointool}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Charts}_Enterprise_versions_released_before_{21_October_2025}____[v3]_[02]_MTc2MTAwMTIwMDAwMA==07650704d7667899b5681867cc4c7e45");


const PriceChart = (props: { symbol?: any }) => {
    const [isDarkMode, setIsDarkMode] = React.useState(() => {
        const savedMode = localStorage.getItem('darkMode');
        return savedMode === null ? false : savedMode === 'true';
    });
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down(992));
    const [candles, setCandles] = React.useState([]);
    const [searchParams] = useSearchParams();

    useEffect(() => {
        const updateMode = () => {
            setTimeout(() => {
                const savedMode = localStorage.getItem('darkMode');
                if (savedMode) {
                    setIsDarkMode(savedMode === 'true')
                }
            }, 0)
        }
        window.addEventListener('darkModeUpdated', updateMode);
        return () => {
            window.removeEventListener('darkModeUpdated', updateMode);
        };
    }, []);

    useEffect(() => {
        setCandles([])
        let range = '1m'
        if (searchParams) {
            const searchRange = searchParams.get("range")
            if (searchRange) {
                range = searchRange
            }
        }
        fetchData(`/api/cache/chart?symbol=${props.symbol.toUpperCase()}&range=${range}`).then((response) => {
            if (response) {
                const c = response.chart.map((candle: any) => ({
                    date: new Date(candle[0] * 1000),
                    low: parseFloat(candle[1]),
                    high: parseFloat(candle[2]),
                    open: parseFloat(candle[3]),
                    close: parseFloat(candle[4]),
                    volume: parseFloat(candle[5]),
                }))
                setCandles(c)
            }
        })
    }, [props.symbol, searchParams])

    const options: any = {
        data: candles,
        overlays: {
            loading: {
                text: 'Loading...',
            },
            noData: {
                text: 'Loading...',
            },
            noVisibleSeries: {
                text: 'Loading...',
            },
        },
        title: {
            text: `${props.symbol}-USD`,
            fontSize: 14,
            fontWeight: 'bold',
            color: isDarkMode ? '#A5A5A5' : '#5B6E86',
        },
        theme: isDarkMode ? 'ag-financial-dark' : 'ag-financial',
        padding: {
            top: 10,
            right: 0,
            bottom: 5,
            left: 0,
        },
        background: {
            visible: false,
        },
        rangeButtons: false,
    }

    return (
        <div className="chart-container">
            <AgFinancialCharts options={options} style={{margin: "5px 10px 10px 10px", display: "block", overflow: "hidden", height: isMobile ? '225px' : '300px'}}/>
        </div>
    )
}

export default PriceChart