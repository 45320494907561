import React, {useEffect, useState} from "react";
import Container from "react-bootstrap/Container";
import { useLocation } from 'react-router-dom';
import Marquee from 'react-fast-marquee';
import fetchData from "../utils/Fetch";
import limitSignificantDigits from "../utils/LimitSignificantDigits";

function TickerTape() {
    const [tickers, setTickers] = useState([]);
    const location = useLocation();
    const display = !['/login', '/sign-up', '/action', '/reset-password'].includes(location.pathname)

    useEffect(() => {
        fetchData(`/api/tickers?page=1&pageSize=20&sortField=marketCap&sortDirection=desc&ticker=`).then((response) => {
            if (response) {
                setTickers(response.tickers)
            }
        })
    }, [])

    return (
        <Container className={`m-0 px-0 py-3 ticker-tape text-center small ${display ? '' : 'd-none'}`}>
                    <Marquee speed={20}>
                        {tickers.map((ticker: any) => {
                            return (
                                <div key={ticker.id} className="px-3 border-end">
                                    <a href={`/symbol/${ticker.ticker}`} className="ticker-tape-link" style={{
                                        fontSize: '12px',
                                        textDecoration: "none",
                                        fontWeight: '700',
                                        textTransform: 'uppercase',
                                    }}>
                                        <img src={ticker.icon} alt="icon" className="ticker-icon mx-1 mb-1" /><b className="mx-1">{ticker.ticker}</b> {limitSignificantDigits(ticker.price, 8)}
                                    </a>
                                </div>
                            );
                        })}
                    </Marquee>
        </Container>
)
}

export default TickerTape;